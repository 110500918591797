<template lang="pug">
    picture.imagem
        source(:srcset="image.src", v-for="image in contents")
        img(:src="defaultImage.src", :alt="defaultImage.alt")
</template>

<script>
import { props } from '@/mixins/components'

export default {
  name: 'component-imagem',
  props,
  computed: {
    keys () {
      return Object.keys(this.contents)
    },
    defaultImage () {
      return this.contents.desktop
    }
  }
}
</script>

<style lang="stylus" scoped src="./Imagem.styl"></style>
